<template>
  <div style="padding: 20px">
    <el-descriptions title="宝箱" :column="3" border>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-shopping-bag-2"></i>
          总数量
        </template>
        {{ treasure_box.total }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-unlock"></i>
          已开启
        </template>
        {{ treasure_box.opened }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-lock"></i>
          剩余数量
        </template>
        {{ treasure_box.rest }}
      </el-descriptions-item>
    </el-descriptions>

    <el-descriptions style="margin-top: 20px" title="银钥匙" :column="3" border>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-shopping-bag-2"></i>
          总数量
        </template>
        {{ silver_key.total }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-unlock"></i>
          已空投
        </template>
        {{ silver_key.sent }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-lock"></i>
          剩余数量
        </template>
        {{ silver_key.rest }}
      </el-descriptions-item>
    </el-descriptions>

    <el-descriptions style="margin-top: 20px" title="金钥匙" :column="3" border>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-shopping-bag-2"></i>
          总数量
        </template>
        {{ golden_key.total }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-unlock"></i>
          已空投
        </template>
        {{ golden_key.sent }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-lock"></i>
          剩余数量
        </template>
        {{ golden_key.rest }}
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import { getFlyEventData } from "@/api/nft.js";

export default {
  data() {
    return {
      treasure_box: { total: 100, opened: 100, rest: 100 },
      silver_key: { total: 100, sent: 100, rest: 100 },
      golden_key: { total: 100, sent: 100, rest: 100 },
    };
  },
  mounted() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      try {
        const { data } = await getFlyEventData();

        this.treasure_box = data.data.treasure_box;
        this.silver_key = data.data.silver_key;
        this.golden_key = data.data.golden_key;
        console.log(data);
      } catch (error) {}
    },
  },
};
</script>

<style lang="less" scoped></style>
